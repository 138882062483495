import * as React from "react";

const iframeStyle = {
    overflow: "hidden",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
};
export default () => {
    React.useEffect(() => {
        let iframeSrc = "https://engage.orbita.cloud/oeapi/bot/hub/orbita";
        if (
            typeof window !== "undefined" &&
            window.location &&
            window.location.search
        ) {
            iframeSrc = iframeSrc + window.location.search;
        }
        const frame: any = document.getElementById("bot-frame");
        frame ? (frame.src = iframeSrc) : null;
    });

    return (
        <iframe
            frameborder="0"
            id="bot-frame"
            // @ts-ignore
            style={iframeStyle}
            height="100%"
            width="100%"
        ></iframe>
    );
};
